<template>
  <div id="app">
    <!-- ! Menu lateral 14 agosto 2023 -->
    <!-- Este vendria siendo el menu lateral que tengo actualmente... -->
    <nav class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
      <!-- <a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#">Piscinas</a> -->
      <a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3">Piscinas</a>
      <!-- BOTON Hamburguesa -->
      <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <ul class="navbar-nav px-3">
        <li class="nav-item text-nowrap">
          <a class="nav-link">Cerrar sesión</a>
        </li>
      </ul>
    </nav>
    <!-- </Este vendria siendo el menu lateral que tengo actualmente... -->
    <div class="container-fluid">
      <div class="row">
        <!-- ! MENU LATERAL IZQUIERDO -->
        <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
          <div class="sidebar-sticky pt-3">
            <ul class="nav flex-column">
              <li class="nav-item" v-if="isActive">
                <a class="nav-link active">
                  <span data-feather="home"></span>
                  Dashboard <span class="sr-only">(current)</span>
                </a>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'pool'}" v-if="isActive" v-show="esGerente || esGestor" exact>
                  <i class="fa-solid fa-water-ladder"></i> Piscina
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'pool-management'}" v-if="isActive" v-show="esGerente || esGestor" exact>
                  <i class="fas fa-swimming-pool"></i> Gestion piscina
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'user'}" v-if="isActive" v-show="esGerente " exact>
                  <i class="fas fa-user"></i> Usuario
                </router-link>
              </li>
              <!-- <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'parameterization'}" v-if="isActive" v-show="esGerente || esGestor" exact>
                  <i class="fa-solid fa-eye-dropper"></i> Parametrización
                </router-link>
              </li> -->


              <!-- <li class="nav-item dropdown" v-if="isActive">
                <router-link 
                  class="nav-link dropdown-toggle nav-link" 
                  data-toggle="dropdown"
                  aria-expanded="false"
                  :to="{name: 'parameterization'}"
                  v-if="isActive" 
                  v-show="esGerente || esGestor" exact>
                  <i class="fa-solid fa-eye-dropper"></i> Parametrización
                </router-link>
                <div class="dropdown-menu" v-if="isActive" v-show="esGerente ">
                  <router-link class="dropdown-item" :to="{name: 'normativity'}">Normatividad</router-link>
                </div>
              </li> -->

              <li class="nav-item dropdown" v-if="isActive">
                <router-link 
                 class="nav-link dropdown-toggle nav-link" 
                 data-toggle="dropdown"
                 aria-expanded="false"
                 :to="{ name: 'parameterization' }"
                 v-if="isActive" 
                 v-show="esGerente || esGestor" exact>
                 <i class="fa-solid fa-eye-dropper"></i> Parametrización
                </router-link>
                <!-- ! TEMPORALMENTE ESTOS ITEMS QUEDARAN ASÍ, PERO Hay que cambiarlos pronto. -->
                <div class="dropdown-menu" v-if="isActive" v-show="esGerente">
                  <router-link class="dropdown-item" :to="{ name: 'normativity' }">Normatividad</router-link>
                  <router-link class="dropdown-item" :to="{ name: 'current-rule' }">Norma vigente</router-link> <!-- Nuevo enlace -->
                </div>
              </li>        

              <li class="nav-item dropdown" v-if="isActive">
                <router-link 
                  class="nav-link dropdown-toggle nav-link" 
                  data-toggle="dropdown"
                  aria-expanded="false"
                  :to="{name: 'chemist'}" 
                  v-if="isActive" 
                  v-show="esGerente || esGestor" exact>
                  <i class="fa-solid fa-flask-vial"></i> Químico
                </router-link>
                <div class="dropdown-menu">
                  <router-link class="dropdown-item" :to="{name:'inventory'}">Inventario</router-link>
                </div>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'notification-manager'}" v-if="isActive" v-show="esGerente" exact>
                  <i class="fa-regular fa-bell"></i>
                  <span class="badge rounded-pill badge-notification bg-danger">{{ nNotify }}</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'notification-gestor'}" v-if="isActive" v-show="esGestor" exact>
                  <i class="fa-regular fa-bell"></i>
                  <span class="badge rounded-pill badge-notification bg-danger">{{ nNotifyGestor }}</span>
                </router-link>
              </li>
              <li class="nav-item dropdown" v-if="isActive">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                  <i class="fa-regular fa-circle-user"></i>
                   {{ getInfoUser.name.charAt(0) }}
                   <!-- {{ getInfoUser.name }} -->
                </a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#">{{  getInfoUser.name }}</a>
                  <a class="dropdown-item" href="#">{{  getInfoUser.email }}</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item logout" @click="logOut()">
                    <i class="fa-solid fa-right-from-bracket"></i>
                    Cerrar Sesión
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        <!-- ! </ FIN - MENU LATERAL IZQUIERDO -->

        <!-- CONTENIDO PRINCIPAL - LA DATA -->
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
          <router-view/>
        </main>
        <!-- </CONTENIDO PRINCIPAL - LA DATA -->
      </div>
    </div>
    <!-- ! </Menu lateral -->
    <!-- <router-view/> UBICACIÓN ORIGINAL  -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
// import NotificationGerente from './components/NotificationGerente.vue';
/* eslint-disable */
export default {
  components:{
    // NotificationGerente
  },
  data(){
    return {
      /*** 6 sept 2023 - ESTAS DOS PROPIEDADES NO SE ESTAN
       * USANDO - ELIMINALAS... REVISA PRIMERO SU REFERENCIA...
       */
      totalNotify:0,
      totalNotifyGestor:0
    }
  },
  created(){
    /* AutoLogin */
    this.readToken();
  },
  methods:{
    ...mapActions(['logOut','readToken']),
  },
  computed:{
    ...mapGetters(['isActive','getInfoUser']),
    ...mapState(['userDb','nNotify','nNotifyGestor']),
    // Manejo de roles - luego llamo a estas computed en las ruta
    esGerente(){
      return this.userDb && this.userDb.role == 'GERENTE';
    },
    esGestor(){
      return this.userDb && this.userDb.role == 'GESTOR';
    }
  }
}
</script>
<style>
header{
  padding: 10px;
}
.nav-link{
  font-size: 20px;
}

.logout{
  cursor: pointer;
}
/* nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
