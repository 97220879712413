import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/* Personal Instalation */
import 'bootstrap'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'

import axios from 'axios'
import VueAxios from 'vue-axios'
import $ from 'jquery'


import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Fragment from 'vue-fragment'

Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(Toast)
Vue.use(Fragment.Plugin)
// Vue.use(Toast, {
//   transition: "Vue-Toastification__bounce",
//   maxToasts: 20,
//   newestOnTop: true
// });
$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})


Vue.config.productionTip = false

//! Esta es la URL DEL BACKEND EN  DESARROLLO 
// axios.defaults.baseURL = 'http://localhost:3000/v1';

//! Esta es la url del Backend en Produccion - VERCEL 
axios.defaults.baseURL = 'https://pool-api-treea.vercel.app/v1';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
