import Vue from 'vue'
import VueRouter from 'vue-router'
import jwtDecode from 'jwt-decode'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }

  /**
   * ! 14 agosto elimine el item inicio del menu
   */
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
  //   meta: {
  //     esGerente: true,
  //     esGestor: true   
  //   }
  // },
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../components/Login.vue'),
    meta: {
      libre: true
    }
  },
  {
    path: '/usuario',
    name: 'user',
    component: () => import(/* webpackChunkName: "usuario" */ '../components/CreateUser.vue'),
    meta: {
      esGerente: true,
    }
  },
  {
    path: '/piscina',
    name: 'pool',
    component: () => import(/* webpackChunkName: "piscina" */ '../components/CreatePool.vue'),
    meta: {
      esGerente: true,
      esGestor: true   
    }
  },
  {
    path: '/detalle-piscina/:poolId',
    name: 'pool-detail',
    component: () => import(/* webpackChunkName: "pool-detail" */ '../components/poolDetail.vue'),
    meta: {
      esGerente: true,
      esGestor: true   
    }
  },
  {
    path: '/gestion-piscina',
    name: 'pool-management',
    component: () => import(/* webpackChunkName: "pool-management" */ '../views/PoolManagementView.vue'),
    meta: {
      esGerente: true,
      esGestor: true   
    }
  },
  /**
  * ! NO SE ESTA USANDO ESTA RUTA? 
  */
  {
    path: '/parametro',
    name: 'param',
    component: () => import(/* webpackChunkName: "param" */ '../views/ParamView.vue'),
    meta: {
      esGerente: true,
      esGestor: true   
    }
  },
  {
    path: '/param/:poolId',
    name: 'param-redirect',
    component: () => import(/* webpackChunkName: "param" */ '../components/Param.vue'),
    meta: {
      esGerente: true,
      esGestor: true   
    }
  },
  {
    path: '/quimico',
    name: 'chemist', //kemish
    component: () => import(/* webpackChunkName: "usuario" */ '../components/CreateChemist.vue'),
    meta: {
      esGerente: true,
      esGestor: true 
    }
  },
  {
    path: '/detalle-quimico/:chemicalId',
    name: 'detalle-quimico',
    component: () => import(/* webpackChunkName: "chemical-detail" */ '../components/ChemicalDetail.vue'),
    meta: {
      esGerente: true,
      esGestor: true   
    }
  },
  {
    path: '/inventario',
    name: 'inventory',
    component: () => import(/* webpackChunkName: "inventory" */ '../components/Inventory.vue'),
    meta: {
      esGerente: true,
      esGestor: true   
    }
  },
  {
    // path: '/historico-aforo', ORIGINAL PATH 
    path: '/historico-aforo/:poolId',
    name: 'history-aforo',
    component: () => import(/* webpackChunkName: "history-aforo" */ '../components/HistoryAforo.vue'),
    meta: {
      esGerente: true,
      esGestor: true   
    }
  },
  {
    path: '/parametrizacion',
    name: 'parameterization',
    component: () => import(/* webpackChunkName: "parametrizacion" */ '../components/Parameterization.vue'),
    meta: {
      esGerente: true,
      esGestor: true   
    }
  },
  {
    path: '/detalle-parametrizacion/:detalleId',
    name: 'parameterization-detail',
    component: () => import(/* webpackChunkName: "parameterization-detail" */ '../components/parameterizationDetail.vue'),
    meta: {
      esGerente: true,
      esGestor: true   
    }
  },
  // add 15 agosto 2023
  {
    path: '/normatividad',
    name: 'normativity',
    component: () => import(/* webpackChunkName: "normatividad" */ '../components/Normativity.vue'),
    meta: {
      esGerente: true,
      esGestor: true   
    }
  },
  {
    path: '/norma-vigente',
    name: 'current-rule',
    component: () => import(/* webpackChunkName: "normatividad" */ '../components/CurrentRule.vue'),
    meta: {
      esGerente: true,
      esGestor: true   
    }
  },
  /*** 25 julio 2023 ***/
  {
    path: '/notificaciones',
    name: 'notification-manager',
    component: () => import(/* webpackChunkName: "Notification" */ '../components/NotificationGerente.vue'),
    meta: {
      esGerente: true,
      // esGestor: true   
    }
  },
  /*** </25 julio 2023 ***/
  /*** 26 julio 2023 ***/
  {
    path: '/notificaciones-gestor',
    name: 'notification-gestor',
    component: () => import(/* webpackChunkName: "Notification" */ '../components/NotificationGestor.vue'),
    meta: {
      // esGerente: true,
      esGestor: true   
    }
  },
  /**
   * ! OJO - A ESTA RUTA SOLO TIENE ACCESO UN USUARIO CON ROL ...
   */
  {
    path: '/detalle-normatividad/:normativityId',
    name: 'normativity-detail',
    component: () => import(/* webpackChunkName: "normativity-detail" */ '../components/NormativityDetail.vue'),
    meta: {
      esGerente: true,
      esGestor: true   
    }
  },
  /*
    Add 1 noviembre 2023 - detalle ajuste dosificación 
    ? Lunes 6 noviembre 2023 - corrección bugs - ajustes
    ? Se agrega un segundo parametro 'nameParam', para poder mostrar el detalle del ajuste de dosificación de forma correcta... 
  */
  {
    path: '/detalle-historico-piscina/:historyPoolId/:nameParam',
    name: 'history-pool-detail',
    component: () => import(/* webpackChunkName: "history-pool-detail" */ '../components/historyPool/HistoryPoolDetail.vue'),
    meta: {
      esGerente: true,
      esGestor: true   
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active' // Aquí se define la clase activa
})

// Interceptores de navegación entre rutas - se ejecutara cada vez que se haga un cambio en la ruta
router.beforeEach((to,from,next)=>{
  try {
    let loggedIn = localStorage.getItem('token')
    if(!loggedIn){
      next()
    }else{
      let token = localStorage.getItem('token')
      const tokenDecodeUser = jwtDecode(token);
    
      if(to.matched.some(record=> record.meta.libre)){
        next()
      }else{
        if(loggedIn && tokenDecodeUser.role == 'GERENTE' && to.matched.some(record=> record.meta.esGerente)){
          next();
        }else{
          if(loggedIn && tokenDecodeUser.role == 'GESTOR' && to.matched.some(record=> record.meta.esGestor)){
            next();
          } else{
            next({name: 'login'});
          }
        }
      }

    }
    
  } catch (error) {
    console.log('Error en el middleware:', error);
    next(false); // Llamada a next(false) para abortar la navegación
  }
})

export default router
